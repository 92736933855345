.tableWrap {
  display: block;
  max-width: 100%;
  overflow-y: scroll;
  width: 1400px;
  height: 500px;
}

table {
  /* Make sure the inner table is always as wide as needed */
  border-spacing: 0;
}

.pagination {
  padding: 1rem;
  justify-content: center;
}

.pagination button {
  padding: 4px;
  width: 100px;
  height: 30px;
  margin: 3px;
}

.numberOfEnteries {
  padding: 4px;
  width: 100px;
  height: 30px;
  margin: 3px;
}

input {
  padding: 4px;
  width: 100px;
  height: 30px;
  margin: 3px;
  font-size: 7px;
}

.btn-grp-2 {
  margin-left: 50px;
}
