.example-fill-remaining-space {
  flex: 1 1 auto;
}

#myHeading {
  text-shadow: 2px 2px 10px black;
}

@media (max-width: 800px) {
  #myHeading {
    display: none;
  }
}
