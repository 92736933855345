table {
  border-spacing: 0;
  font-size: smaller;
  border: 1px solid #ededed;
}

.occupancyBox {
  background-color: white;
  position: sticky;
  top: 64px;
  z-index: 3;
  padding: 6px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}

/* .occupancyBox-mobile {
  background-color: white;
  position: sticky;
  top: 1420px;
  z-index: 3;
  padding: 6px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}

.occupancyBox-tab {
  background-color: white;

  z-index: 3;
  padding: 6px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}
 */

.occupancyBox1 {
  background-color: white;
  position: sticky;
  top: 2500px;
  z-index: 3;
  padding: 6px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}

#sticky-thc {
  position: sticky;
  top: 195px;
  z-index: 3;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}

/* #sticky-thc-tab {
  z-index: 3;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}

#sticky-thc-tab-mobile {
  position: sticky;
  top: 1700px;
  z-index: 3;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
} */


table tr:last-child td {
  border-bottom: 0;
}

table th {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
 
}

table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

#footer-row {
  border-top: 2px solid #87898b;
  font-weight: 1000;
}

table th.sort-asc::before {
  line-height: 50px;
  position: relative;
  margin-left: 10px;
  margin-top: 10px;

  text-align: left;
}
table th.sort-desc::before {
  line-height: 50px;
  position: relative;
  margin-left: 10px;
  margin-top: 10px;

  text-align: left;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.badge {
  background-color: #00be43;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.badgeTotalNumber {
  background-color: #9ae6b4;
  color: #2f5e8c;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
}
input {
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

.legend-box {
  padding: 10px;
  float: inline-end;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 10px 10px rgba(69, 90, 100, 0.08);
  box-shadow: 0px 10px 10px 0px rgba(69, 90, 100, 0.08);
  border: none;
}
.legend-labels {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 5px;
}

#circle1 {
  width: 1px;
  height: 1px;
  background: #ff7f7f;
  border-radius: 50%;
}
#circle2 {
  width: 100px;
  height: 100px;
  background: #ff7f7f;
  border-radius: 50%;
}
#circle3 {
  width: 100px;
  height: 100px;
  background: #f2e34c;
  border-radius: 50%;
}
#circle4 {
  width: 100px;
  height: 100px;
  background: #9ae6b4;
  border-radius: 50%;
}

.legend-labels > div {
  padding-right: 20px;
  padding-left: 20px;
}

.card-title {
  color: #2f5e8c;
}
#new-tooltip span {
  visibility: hidden;
  width: auto;

  background-color: rgb(68, 65, 65);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 10%;
  margin-bottom: 10px;

  opacity: 0.9;
}
#new-tooltip:hover span {
  visibility: visible;
}
