table.overview th:nth-child(3) {
  display: none;
}
table.overview td:nth-child(3) {
  display: none;
}
table.overview th:nth-child(4) {
  display: none;
}
table.overview td:nth-child(4) {
  display: none;
}
table.overview th:nth-child(5) {
  display: none;
}
table.overview td:nth-child(5) {
  display: none;
}
table.overview th:nth-child(6) {
  display: none;
}
table.overview td:nth-child(6) {
  display: none;
}
table.overview th:nth-child(7) {
  display: none;
}
table.overview td:nth-child(7) {
  display: none;
}
table.overview th:nth-child(8) {
  display: none;
}
table.overview td:nth-child(8) {
  display: none;
}
table.overview th:nth-child(9) {
  display: none;
}
table.overview td:nth-child(9) {
  display: none;
}
table.overview th:nth-child(10) {
  display: none;
}
table.overview td:nth-child(10) {
  display: none;
}


table.adult th:nth-child(2) {
  display: none;
}
table.adult td:nth-child(2) {
  display: none;
}
table.adult th:nth-child(3) {
  display: none;
}
table.adult td:nth-child(3) {
  display: none;
}
table.adult th:nth-child(4) {
  display: none;
}
table.adult td:nth-child(4) {
  display: none;
}
table.adult th:nth-child(5) {
  display: none;
}
table.adult td:nth-child(5) {
  display: none;
}
table.adult th:nth-child(6) {
  display: none;
}
table.adult td:nth-child(6) {
  display: none;
}
table.adult th:nth-child(7) {
  display: none;
}
table.adult td:nth-child(7) {
  display: none;
}
table.adult th:nth-child(8) {
  display: none;
}
table.adult td:nth-child(8) {
  display: none;
}
table.adult th:nth-child(9) {
  display: none;
}
table.adult td:nth-child(9) {
  display: none;
}

table.pediatric th:nth-child(2) {
  display: none;
}
table.pediatric td:nth-child(2) {
  display: none;
}
table.pediatric th:nth-child(3) {
  display: none;
}
table.pediatric td:nth-child(3) {
  display: none;
}
table.pediatric th:nth-child(4) {
  display: none;
}
table.pediatric td:nth-child(4) {
  display: none;
}
table.pediatric th:nth-child(5) {
  display: none;
}
table.pediatric td:nth-child(5) {
  display: none;
}
table.pediatric th:nth-child(6) {
  display: none;
}
table.pediatric td:nth-child(6) {
  display: none;
}
table.pediatric th:nth-child(7) {
  display: none;
}
table.pediatric td:nth-child(7) {
  display: none;
}
table.pediatric th:nth-child(8) {
  display: none;
}
table.pediatric td:nth-child(8) {
  display: none;
}
table.pediatric th:nth-child(9) {
  display: none;
}
table.pediatric td:nth-child(9) {
  display: none;
}


table.specialty th:nth-child(2) {
  display: none;
}
table.specialty td:nth-child(2) {
  display: none;
}
table.specialty th:nth-child(3) {
  display: none;
}
table.specialty td:nth-child(3) {
  display: none;
}
table.specialty th:nth-child(4) {
  display: none;
}
table.specialty td:nth-child(4) {
  display: none;
}
table.specialty th:nth-child(5) {
  display: none;
}
table.specialty td:nth-child(5) {
  display: none;
}
table.specialty th:nth-child(6) {
  display: none;
}
table.specialty td:nth-child(6) {
  display: none;
}
table.specialty th:nth-child(7) {
  display: none;
}
table.specialty td:nth-child(7) {
  display: none;
}
table.specialty th:nth-child(8) {
  display: none;
}
table.specialty td:nth-child(8) {
  display: none;
}
table.specialty th:nth-child(9) {
  display: none;
}
table.specialty td:nth-child(9) {
  display: none;
}


table.psychiatry th:nth-child(2) {
  display: none;
}
table.psychiatry td:nth-child(2) {
  display: none;
}
table.psychiatry th:nth-child(3) {
  display: none;
}
table.psychiatry td:nth-child(3) {
  display: none;
}
table.psychiatry th:nth-child(4) {
  display: none;
}
table.psychiatry td:nth-child(4) {
  display: none;
}
table.psychiatry th:nth-child(5) {
  display: none;
}
table.psychiatry td:nth-child(5) {
  display: none;
}
table.psychiatry th:nth-child(6) {
  display: none;
}
table.psychiatry td:nth-child(6) {
  display: none;
}
table.psychiatry th:nth-child(7) {
  display: none;
}
table.psychiatry td:nth-child(7) {
  display: none;
}
table.psychiatry th:nth-child(8) {
  display: none;
}
table.psychiatry td:nth-child(8) {
  display: none;
}
table.psychiatry th:nth-child(9) {
  display: none;
}
table.psychiatry td:nth-child(9) {
  display: none;
}


table.mombaby th:nth-child(2) {
  display: none;
}
table.mombaby td:nth-child(2) {
  display: none;
}
table.mombaby th:nth-child(3) {
  display: none;
}
table.mombaby td:nth-child(3) {
  display: none;
}
table.mombaby th:nth-child(4) {
  display: none;
}
table.mombaby td:nth-child(4) {
  display: none;
}
table.mombaby th:nth-child(5) {
  display: none;
}
table.mombaby td:nth-child(5) {
  display: none;
}
table.mombaby th:nth-child(6) {
  display: none;
}
table.mombaby td:nth-child(6) {
  display: none;
}
table.mombaby th:nth-child(7) {
  display: none;
}
table.mombaby td:nth-child(7) {
  display: none;
}
table.mombaby th:nth-child(8) {
  display: none;
}
table.mombaby td:nth-child(8) {
  display: none;
}
table.mombaby th:nth-child(9) {
  display: none;
}
table.mombaby td:nth-child(9) {
  display: none;
}
/* table.mombaby td:nth-child(5) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.mombaby th:nth-child(5) {
  border-right: 4px solid rgb(237, 237, 237);
} */
.tableWrap {
  display: block;
  height: 600px;
  overflow-y:scroll;
  overflow-x: scroll;
  width: auto;

}

.tableWrap-tab {
  display: block;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  width: auto;
  height: 800px;
}

