.landing-page-cont {
  background-color: transparent;
  color: white;
  height: 95vh;
  z-index: 1;
  width: 100%;
}

.landing-page-cont-group {
  width: 100%;
  display: grid;
  background-color: black;
  z-index: 1;
}

#landing-page-cont-main {
  background-color: transparent;
  color: white;
  height: 95vh;
  padding-top: 300px;
  z-index: 1;
  width: 700px;
}

@media (max-width: 600px) {
  .landing-page-cont-group {
    grid-template-columns: repeat(1, 1fr);
  }
  .gradBack {
    max-width: 350px;
    height: 95vh;
    background-color: linear-gradient(
      90deg,
      hsla(39, 100%, 71%, 1) 0%,
      hsla(216, 100%, 62%, 1) 100%
    );
    filter: brightness(90%);
  }
  .landing-page-cont-group {
    display: grid;
    background-color: black;
    width: 300px;
  }
  #landing-page-cont-main {
    background-color: transparent;
    color: white;
    height: 50vh;
    padding-top: 100px;
    z-index: 1;
    width: 200px;
  }
}

@media (min-width: 900px) {
  .landing-page-cont-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.gradBack {
  width: 500px;
  height: 95vh;
  background: linear-gradient(0deg, #656d75 0%, #3697cf 100%);
  filter: brightness(90%);
}
